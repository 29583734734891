import styles from "./styles.module.scss";
import { useContext, useEffect, useState } from "react";

import Parameter from "../../components/Parameter";
import { APIContext } from "../../contexts/ApiContext";
import Dialog from "../../components/Dialog";
import { LongSwitch } from "../../components/LongSwitch";
import { BarLoader } from "react-spinners";

export type Group = {
  id: string;
  name: string;
  type: string;
  data: {
    set_point: number;
    pre_set_point?: number;
    unit: string;
    range: number[];
  };
};

function Parameters() {
  const [groups, setGroups] = useState([] as Group[]);
  const [configState, setConfigState] = useState<boolean | null>(null);
  const [confirmation, setConfirmation] = useState(false);
  const { putStateApi, getGroupsApi, getConfigApi } = useContext(APIContext);

  useEffect(() => {
    getState();
    getGroups();
    //eslint-disable-next-line
  }, []);

  async function getGroups() {
    const data = await getGroupsApi();
    setGroups(data);
  }

  async function getState() {
    const data = await getConfigApi();
    setConfigState(data.state === "acclimatization");
  }

  async function handleConfirmation() {
    setConfirmation(!confirmation);
  }
  async function handleChangeState() {
    await putStateApi(
      configState ? "pre_acclimatization" : "acclimatization"
    ).then(() => setConfigState(!configState));
    setConfirmation(false);
  }

  return (
    <>
      {confirmation && (
        <Dialog title="Confirmar mudança">
          <>
            <p className={styles.desc}>
              Você tem certeza que deseja trocar o estado da estufa?
            </p>
            <div className={styles.buttons}>
              <button
                onClick={handleConfirmation}
                type="button"
                className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
              >
                Cancelar
              </button>

              <button
                onClick={handleChangeState}
                type="button"
                className="text-white bg-green-600 hover:bg-green-800 focus:ring-2 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none"
              >
                Alterar estado
              </button>
            </div>
          </>
        </Dialog>
      )}
      <div className={styles.parameters}>
        <div className={styles.state}>
          <h3 className={styles.title}>Estado da Estufa</h3>
          {configState === null ? (
            <BarLoader color="#339933" />
          ) : (
            <LongSwitch
              labelOne="Pré Aclimatação"
              labelTwo="Aclimatação"
              click={() => handleConfirmation()}
              value={configState}
            />
          )}
        </div>
        <section>
          {groups
            .filter(
              (group) => group.type === "sensors" && "set_point" in group.data
            )
            .map((group) => (
              <Parameter key={group.id} group={group} />
            ))}

          {groups
            .filter(
              (group) =>
                group.type === "sensors" &&
                "set_point" in group.data &&
                "pre_set_point" in group.data
            )
            .map((group) => (
              <Parameter key={group.id} group={group} pre_acclimatization />
            ))}
        </section>
      </div>
    </>
  );
}

export default Parameters;
