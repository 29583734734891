interface Props {
  labelOne?: string;
  labelTwo?: string;
  click: () => void;
  value: boolean;
}

export const LongSwitch = ({ labelOne, labelTwo, click, value }: Props) => {
  return (
    <div
      className="cursor-pointer w-fit flex rounded items-center px-2 py-1 bg-gray-200"
      onClick={click}
    >
      <div
        className={`font-semibold rounded ${
          value ? `bg-gray-200` : "border-2 text-red-500 bg-white"
        }  px-6 py-2 mr-2`}
      >
        {labelOne}
      </div>
      <div
        className={`font-semibold rounded ${
          value ? "border-2 text-blue-500 bg-white" : ""
        }  px-6 py-2`}
      >
        {labelTwo}
      </div>
    </div>
  );
};
