import { useEffect } from "react";
import styles from "./styles.module.scss";

interface WindroseProps {
  windSpeed: string;
  position: number;
}

export const Windrose = (props: WindroseProps) => {
  useEffect(() => {
    const windroseDiv = document.getElementById("windrose");
    windroseDiv?.style.setProperty("--dial-degree", `${props.position}deg`);
  }, [props.position]);
  return (
    <div id="windrose" className={styles.container}>
      <blockquote className={styles.display}>
        <p className={styles.value}>{props.windSpeed}</p>
        <p>m/s</p>
      </blockquote>
      <span className={styles.N}>N</span>
      <span className={styles.NE}>NE</span>
      <span className={styles.E}>L</span>
      <span className={styles.SE}>SE</span>
      <span className={styles.S}>S</span>
      <span className={styles.SW}>SO</span>
      <span className={styles.W}>O</span>
      <span className={styles.NW}>NO</span>
      <div className={styles.dial}></div>
    </div>
  );
};
