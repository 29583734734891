import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { IActuator } from "../../interfaces/Actuator";
import { IHistory } from "../../interfaces/History";
import { api } from "../../services/api";
import { BarLoader } from "react-spinners";
import { titles } from "../Controls";

export const ActuatorDetails = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const actuator: IActuator = state;
  const [actuatorHistory, setActuatorHistory] = useState<IHistory[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [tooltip, setTooltip] = useState<boolean>(false);

  const getActuatorHistory = async (
    actuator_id: string
  ): Promise<IHistory[] | null> => {
    try {
      const { data, status } = await api.get(
        `history/actuators/${actuator_id}`
      );

      if (status >= 200 && status < 300) {
        setActuatorHistory(data);
        setIsLoading(false);
      }
    } catch (_) {
      // setError("Erro ao obter os usuários.");
    }

    return null;
  };

  useEffect(() => {
    getActuatorHistory(actuator.id);
    const interval = setInterval(() => {
      getActuatorHistory(actuator.id);
    }, 60000);

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className={styles.actuatorDetails}>
        <div className={styles.header}>
          <span
            className="material-symbols-outlined"
            onClick={() => navigate("/controls")}
          >
            arrow_back
          </span>
          <h2>{actuator?.name}</h2>
        </div>
        <div className={styles.details}>
          <section className={styles.info}>
            <h3>Ficha técnica</h3>
            <div>
              <p>
                <strong>Identificador:</strong> {actuator?.id}
              </p>
              <p>
                <strong>Rasp:</strong> {actuator?.adress.rasp_id}
              </p>
              <p>
                <strong>Pino:</strong> {actuator?.adress.pin}
              </p>
              <p>
                <strong>Valor atual:</strong> {String(actuator?.current_value)}
              </p>
              <p>
                <strong>Tipo</strong> {actuator?.subtype}
              </p>
              <p>
                <strong>Rotinas:</strong>{" "}
                {actuator.routines?.map((str) => titles[str]).join(", ") ??
                  "Nenhuma rotina é associada a esse atuador."}
              </p>
            </div>
          </section>
          <div className={styles.divider} />
          <section className={styles.history}>
            <h3
              className="flex items-center w-fit"
              data-tooltip-target="tooltip-default"
            >
              Histórico de acionamento{" "}
              <div
                className={styles.tooltip}
                onMouseOver={() => setTooltip(true)}
                onMouseLeave={() => setTooltip(false)}
              >
                i
              </div>
            </h3>
            <div
              id="tooltip-default"
              role="tooltip"
              className={`${tooltip ? "" : "hidden"
                } absolute z-10 inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm tooltip`}
            >
              Listagem dos últimos 10 registros de acionamento do atuador{" "}
              {actuator?.name}
              <div className="tooltip-arrow" data-popper-arrow></div>
            </div>
            <div className="relative overflow-x-auto border-1 border-gray-500 rounded-sm">
              <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Carimbo data/hora
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Realizado por
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Via (aplicação)
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Valor
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {actuatorHistory.map((history, index) => (
                    <tr key={index} className="bg-white border-b">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                      >
                        {new Date(history.timestamp.$date).toLocaleDateString()}
                        {" - "}
                        {new Date(history.timestamp.$date).toLocaleTimeString()}
                      </th>
                      <td className="px-6 py-4">
                        {history.user ? history.user.name : history.caller_id}
                      </td>
                      <td className="px-6 py-4">{history.caller_app}</td>
                      <td className="px-6 py-4">
                        {String(history.current_value)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {isLoading && (
              <div className="flex items-center justify-center mt-8">
                <BarLoader color="#339933" />
              </div>
            )}
            {actuatorHistory.length === 0 && !isLoading && (
              <p className={styles.noHistory}>
                Nenhum registro de acionamento encontrado para o atuador{" "}
                {actuator.name}.
              </p>
            )}
          </section>
        </div>
      </div>
    </>
  );
};
