// import { useContext } from "react";
import { Outlet } from "react-router-dom";
// import { APIContext } from "../../contexts/ApiContext";
// import ErrorMessage from "../ErrorMessage";
import Header from "../Header";

import styles from "./styles.module.scss";

function Layout({ children }: { children?: JSX.Element | JSX.Element[] }) {
  // const { error, setError } = useContext(APIContext);

  return (
    <>
      <Header />
      <main className={styles.main}>
        {/* {error && <ErrorMessage clear={setError} message={error} />} */}
        <Outlet />
        {children}
      </main>
    </>
  );
}

export default Layout;
