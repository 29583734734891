import { Fragment } from "react";
import styles from "./styles.module.scss";

const hours = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
];

interface ITimePicker {
  value: string;
  label: string;
  changeValue: (val: string) => void;
}

export default function TimePicker({ value, changeValue, label }: ITimePicker) {
  return (
    <div className={styles.hour}>
      <label htmlFor="startTime">
        {label}
        <span>*</span>
      </label>
      <div className={styles.select}>
        <svg
          width="1.25em"
          height="1.25em"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 12C0 5.37 5.364 0 11.994 0S24 5.37 24 12s-5.376 12-12.006 12S0 18.63 0 12zm2.4 0c0 5.304 4.296 9.6 9.6 9.6 5.304 0 9.6-4.296 9.6-9.6 0-5.304-4.296-9.6-9.6-9.6A9.597 9.597 0 002.4 12zm8.4-6h1.8v6.3l5.4 3.204-.906 1.476L10.8 13.2V6z"
            fill="#212134"
          ></path>
        </svg>
        <select
          value={value}
          onChange={(e) => changeValue(e.target.value)}
          name="startTime"
          id="startTime"
        >
          {hours.map((item) => (
            <Fragment key={item}>
              <option value={`${item}:00`}>{`${item}:00`}</option>
              <option value={`${item}:15`}>{`${item}:15`}</option>
              <option value={`${item}:30`}>{`${item}:30`}</option>
              <option value={`${item}:45`}>{`${item}:45`}</option>
            </Fragment>
          ))}
        </select>
      </div>
    </div>
  );
}
