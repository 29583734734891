export class DateConversion {
  private static readonly TIMEZONE = "pt-BR";

  static stringDateToLocalDatePlusTimestamp(
    stringDate: string,
    timestamp: number = 0
  ) {
    const date = new Date(stringDate);
    const dateMilliseconds = date.getTime() + timestamp;
    date.setTime(dateMilliseconds);

    return (
      date.toLocaleDateString(DateConversion.TIMEZONE) +
      " - " +
      date.toLocaleTimeString(DateConversion.TIMEZONE)
    );
  }
}
