import { Routes, Route } from "react-router-dom";

import Controls from "./pages/Controls";
import DashboardPage from "./pages/Dashboard";
import Parameters from "./pages/Parameters";
import Layout from "./components/Layout";
import { Login } from "./pages/Login";
import { Guard } from "./components/Guard";
import Logs from "./pages/Logs";
import Reports from "./pages/Reports";
import { ReportDetails } from "./pages/ReportDetails";
import { ActuatorDetails } from "./pages/ActuatorDetails";
import { DetailedLogs } from "./pages/DetailedLogs";
import { Executions } from "./pages/Executions";

type Page = {
  title: string;
  to: string;
  component: JSX.Element;
  guardRoute?: boolean;
  hideOnMenu?: boolean;
};

export const pages: Page[] = [
  {
    title: "Dashboard",
    to: "/",
    component: <DashboardPage />,
    guardRoute: true,
  },
  {
    title: "Controle",
    to: "/controls",
    component: <Controls />,
    guardRoute: true,
  },
  {
    title: "Parâmetros",
    to: "/parameters",
    component: <Parameters />,
    guardRoute: true,
  },
  {
    title: "Logs de aplicação",
    to: "/logs",
    component: <Logs />,
    guardRoute: true,
  },
  {
    title: "Relatórios",
    to: "/reports",
    component: <Reports />,
    guardRoute: true,
  },
  {
    title: "Detalhe de Relatório",
    to: "/report/:id",
    component: <ReportDetails />,
    guardRoute: true,
    hideOnMenu: true,
  },
  {
    title: "Detalhe de Atuador",
    to: "/actuators/:id",
    component: <ActuatorDetails />,
    guardRoute: true,
    hideOnMenu: true,
  },
  {
    title: "Execucoes",
    to: "/logs/routines/:routine",
    component: <Executions />,
    guardRoute: true,
    hideOnMenu: true,
  },
  {
    title: "Detalhes dos Logs",
    to: "/logs/routines/:routine/executions/:execution",
    component: <DetailedLogs />,
    guardRoute: true,
    hideOnMenu: true,
  },
  { title: "Login", to: "/login", component: <Login />, hideOnMenu: true },
];

const GuardRoute = (page: Page) => (
  <Route key={page.title} path={page.to} element={<Guard />}>
    <Route path="" element={<Layout />}>
      <Route path="" element={page.component} />
    </Route>
  </Route>
);

const CommonRoute = (page: Page) => (
  <Route key={page.title} path={page.to} element={page.component} />
);

export const AppRoutes = () => (
  <Routes>
    {pages.map((page) =>
      page.guardRoute ? GuardRoute(page) : CommonRoute(page)
    )}
  </Routes>
);
