import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import { Menu, X } from "react-feather";
import useDropdownMenu from "../../utils/useDropdownMenu";
import Logo from "../Logo";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

type Item = {
  title: string;
  to: string;
  hideOnMenu?: boolean;
};

type DrawerProps = {
  items: Item[];
};

function Drawer({ items }: DrawerProps) {
  const { buttonProps, isOpen, setIsOpen } = useDropdownMenu(1);

  let location = useLocation();

  const logout = () => {
    localStorage.clear()
    window.location.replace("/login")
  }

  useEffect(() => {
    setIsOpen(false);
    // eslint-disable-next-line
  }, [location]);

  return (
    <div className={styles.container}>
      <button {...buttonProps}>
        <Menu />
      </button>

      <div
        className={`${styles.drawer} ${isOpen ? styles.open : ""}`}
        role="menu"
      >
        <header>
          <Link to="/">
            <Logo />
          </Link>
          <button
            className={styles.closeButton}
            onClick={() => setIsOpen(false)}
          >
            <X color="#000" />
          </button>
        </header>
        <div className={styles.content}>
          <nav>
            {items.map((item) => {
              if (!item.hideOnMenu) {
                return (
                  <Link key={item.to} to={item.to} className={styles.link}>
                    {item.title}
                  </Link>
                );
              }
              return null;
            })}
          </nav>
          <footer>
            <div className={styles.logout}>
              <button
                onClick={logout}
                type="button"
                className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
              >
                Sair
              </button>
            </div>

            <p>Versão: {process.env.REACT_APP_VERSION}</p>
            <p>Commit: {process.env.REACT_APP_COMMIT?.slice(0, 7)}/main</p>
          </footer>
        </div>
      </div>
      <div className={isOpen ? styles.mask : ""}></div>
    </div>
  );
}

export default Drawer;
