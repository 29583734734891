import { useContext, useEffect, useState } from "react";
import styles from "./averageCard.module.scss";
import { BarLoader } from "react-spinners";
import { APIContext } from "../../contexts/ApiContext";
import { IAverage } from "../../interfaces/Average";
import { Chart } from "../Chart";
import { ChartProps } from "../../interfaces/Chart";

const minuteInTimestamp = 60000;

type Group = {
  id: string;
  name: string;
  type: string;
  subtype: string;
  visible?: boolean;
  data: {
    unit: string;
  };
};

function AverageCard({ group }: { group: Group }) {
  const { getReadsAveragesApi, getReadsAllAveragesApi } =
    useContext(APIContext);
  const [averages, setAverages] = useState({} as IAverage);
  const [isLoading, setIsLoading] = useState(true);
  const [chart, setChart] = useState<ChartProps>({ data: [], labels: [] });

  async function getAverages(groupId: string) {
    setIsLoading(true);

    const avg = await getReadsAveragesApi(groupId);

    setAverages(avg);
    setIsLoading(false);
  }

  useEffect(() => {
    getChartData(group.id);
    getAverages(group.id);

    const interval = setInterval(() => {
      getAverages(group.id);
    }, minuteInTimestamp);

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  const icons: { [key: string]: string } = {
    temperature: "thermostat",
    humidity: "water_drop",
    luminosity: "emoji_objects",
    substrate: "grass",
  };

  const getChartData = async (id: string) => {
    const chartData: ChartProps = { data: [], labels: [] };

    const startAt = Math.floor((new Date(Date.now() - 8 * 60 * 60 * 1000)).getTime() / 1000)
    const endAt = Math.floor(Date.now() / 1000)

    const reads = await getReadsAllAveragesApi(id, startAt, endAt);

    for (const read of reads.slice(-12)) {
      // Correção pois umidade_estufa vem acima de 100, mesmo sendo valor em porcentagem
      chartData.data.push(id === 'umidade_estufa' ? Math.min(read.value, 100) : read.value)
      if (!read.timestamp.substring(11, 16).includes("30")) {
        let convertedToInt = parseInt(read.timestamp.substring(11, 13))
        if (convertedToInt === 0) convertedToInt = 24
        if (convertedToInt === 1) convertedToInt = 25
        if (convertedToInt === 2) convertedToInt = 26
        chartData.labels.push((convertedToInt - 3).toString())
      } else {
        chartData.labels.push("")
      }
    }

    setChart(chartData);
  };

  return (
    <div className={styles.averageCard}>
      {isLoading ? (
        <div className={styles.isLoading}>
          <BarLoader color="#339933" />
        </div>
      ) : (
        <>
          <header>
            <h2 className={styles.title}>
              <span className="material-symbols-outlined">
                {icons[group.subtype]}
              </span>{" "}
              {group.name}
            </h2>
          </header>

          <div className={styles.content}>
            {averages != null && Object.keys(averages).length !== 0 ? (
              <div className={styles.card}>
                <p className={styles.display}>
                  <span className={styles.value}>{averages.value}</span>
                  <span>{group.data.unit}</span>
                </p>
                <p>{new Date(averages.create_at.$date).toLocaleString()}</p>
              </div>
            ) : !isLoading ? (
              <p>Não há dados suficientes.</p>
            ) : null}
          </div>
        </>
      )}
      {chart.data.length > 0 && !isLoading ? (
        <Chart data={chart.data} labels={chart.labels} unit={group.data.unit} />
      ) : null}
    </div>
  );
}
export default AverageCard;