import styles from "./styles.module.scss";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isExpired } from "react-jwt";
import { BarLoader } from "react-spinners";
import { APIContext } from "../../contexts/ApiContext";

export const Login = () => {
  const { getAccountTokenApi } = useContext(APIContext);
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("access_token");

    if (token && !isExpired(token)) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    const data = await getAccountTokenApi(email, password);

    setIsLoading(false);
    localStorage.setItem("access_token", data.acess_token);
    localStorage.setItem("refresh_token", data.refresh_token);

    navigate("/");
  }

  return (
    <div className={styles.login}>
      <section className={styles.wrapper}>
        <header>
          <h1>Entrar na plataforma</h1>
          <img src="/logo192.png" alt="Logo" />
        </header>

        <form onSubmit={(e) => handleSubmit(e)}>
          <label htmlFor="email" className="block text-sm font-medium text-gray-900">Email*</label>
          <input
            type="email"
            id="email"
            className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            placeholder="Email"
            required
            value={email}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setError("");
              setEmail(event.target.value);
            }}
            disabled={isLoading}
          />

          <label htmlFor="password" className="mt-2 block text-sm font-medium text-gray-900">Senha*</label>
          <input
            type="password"
            id="password"
            className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            placeholder="Senha"
            required
            value={password}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setError("");
              setPassword(event.target.value);
            }}
            disabled={isLoading}
          />

          <p className={styles.errorLabel}>{error}</p>

          <div className={styles.action}>
            {isLoading ? <BarLoader color="#339933" /> :
              <button
                type="submit"
                className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-2 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none"
              >
                Entrar
              </button>}
          </div>
        </form>
      </section>
      <section className={styles.info}>
        <header>
          <h1>Smart GreenHouse</h1>
        </header>
        <div className={styles.project}>
          <img src="/images/greenhouse.png" alt="Projeto técnico estufa" />
        </div>
        <div className={styles.sponsors}>
          <img src="/images/sponsors.png" alt="Apoiadores do projeto" />
        </div>
      </section>
    </div>
  );
};
