import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

import styles from "./styles.module.scss";

interface ChartProps {
  data: number[];
  labels: string[];
  unit: string;
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const Chart = ({ data, labels, unit }: ChartProps) => {
  const dataConfig = {
    labels,
    datasets: [
      {
        data,
        borderColor: "#393",
        backgroundColor: "#33993378",
      },
    ],
  };

  function improveValue(values: number[], unit: string) {
    const minValue = Math.max(0, Math.min(...values));
    const maxValue = Math.min(unit === '%' ? 100 : Number.POSITIVE_INFINITY, Math.max(...values));

    const minOffset = unit === 'lux' ? 5000 : 15;
    const maxOffset = unit === 'lux' ? 5000 : 15;

    const min = Math.max(minValue - minOffset, 0);
    const max = Math.min(maxValue + maxOffset, unit === '%' ? 100 : Number.POSITIVE_INFINITY);

    return { min, max };
  }

  return (
    <div className={styles.chart}>
      <p>{unit}</p>
      <Line options={{
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false,
          },
        },
        scales: {
          y: {
            min: improveValue(data, unit).min,
            max: improveValue(data, unit).max,
          }
        }
      }} data={dataConfig} />
    </div>
  );
};
