import React from 'react'

import styles from "./styles.module.scss"

interface IDialog {
    title: string;
    children: any;
}

export default function Dialog({ title, children }: IDialog) {
    return (
        <div className={styles.dialog}>
            <div className={styles.dialogBody}>
                <h2>{title}</h2>
                {children}
            </div>
        </div>
    )
}
