import styles from "./styles.module.scss";
import Select from "../../components/Select/Index";
import { useEffect, useState } from "react";
import { LogsApibn } from "../../components/LogsApibn";
import { api } from "../../services/api";
import { BarLoader } from "react-spinners";

function Logs() {
  const [logs, setLogs] = useState("");
  const [logOption, setLogOption] = useState("routines");
  const [logData, setLogData] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);

  async function getLogs() {
    if (logOption === "routines") {
      setLoading(true);
      try {
        const data = await api.get("/logs/routines");
        setLogData(data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
      return;
    }
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "SGH kzYy29MPhg34hrRBV3cYxytrjgmJq");

    var requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response: any = await fetch(
      `/api/files/stream/logs/${logOption}.log`,
      requestOptions
    );
    const reader = response.body
      .pipeThrough(new TextDecoderStream())
      .getReader();

    let state = logOption;

    while (true) {
      const { value, done } = await reader.read();
      if (done) break;
      if (state !== logOption) break;

      setLogs((prevState) => (prevState += value));
    }
  }

  useEffect(() => {
    setLogs("");
    getLogs();
    // eslint-disable-next-line
  }, [logOption]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen w-screen">
        <BarLoader className="mb-8 mt-4" color="#339933" />
      </div>
    );
  }

  return (
    <>
      <div className={styles.logs}>
        <Select
          id="logs"
          label="Selecione o log"
          required={true}
          value={logOption}
          onChange={setLogOption}
          options={[
            { name: "Rotinas", value: "routines" },
            { name: "Lógica fuzzy", value: "fuzzy" },
            { name: "Integridade APIBN", value: "apibn" },
          ]}
        />
        <section>
          <h2 className={styles.h2}>
            {logOption === "routines" ? "Rotinas" : logOption}
          </h2>
          {logOption !== "routines" ? (
            <pre>{logs}</pre>
          ) : (
            <LogsApibn
              logs={logData}
              thead={["Nome", "Aplicação", "RaspID", "Descrição"]}
            />
          )}
        </section>
      </div>
    </>
  );
}

export default Logs;
