import { BarLoader } from "react-spinners";
import Select from "../../components/Select/Index";
import { useContext, useEffect, useState } from "react";
import ReportItem from "../../components/ReportItem";

import styles from "./styles.module.scss";
import { useQueries } from "react-query";
import Dialog from "../../components/Dialog";
import { APIContext } from "../../contexts/ApiContext";
import { IGroup } from "../../interfaces/Group";
import { IReport } from "../../interfaces/Report";
import TimePicker from "../../components/TimePicker";

function Reports() {
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [startTime, setStartTime] = useState("00:00");
  const [endTime, setEndTime] = useState("00:00");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [groups, setGroups] = useState([] as IGroup[]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { getGroupsApi, getReportsApi, postReportApi } = useContext(APIContext);

  const groupSensors = groups.filter((group) => group.type === "sensors");

  let startTimestamp: number = convertTimestamp(
    new Date(startDate as string),
    startTime
  );
  let endTimestamp: number = convertTimestamp(
    new Date(endDate as string),
    endTime
  );

  const [reportsQuery, groupsQuery] = useQueries([
    {
      queryKey: ["reports"],
      queryFn: () => getReportsApi(),
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      refetchInterval: isDialogOpen ? 100000000 : 10000,
    },
    {
      queryKey: ["groups"],
      queryFn: () => getGroupsApi(),
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
    },
  ]);

  async function createReport(
    startTimestamp: number,
    endTimestamp: number,
    selectedGroup: string
  ) {
    setError("");
    const currentTimestamp = Math.floor(Date.now() / 1000);
    if (selectedGroup === "" || isNaN(startTimestamp) || isNaN(endTimestamp)) {
      setError("Insira todos os campos.");
      return;
    }
    if (startTimestamp > endTimestamp) {
      setError("A data/hora iniciais devem ser menores que as finais.");
      return;
    }
    if (startTimestamp > currentTimestamp || endTimestamp > currentTimestamp) {
      setError("As datas/horas devem ser anteriores à data/hora atual.");
      return;
    }
    setLoading(true);
    const timezone = 3 * 60 * 60
    await postReportApi(selectedGroup, startTimestamp - timezone, endTimestamp - timezone);
    reportsQuery.refetch();
    handleDialog();
    setLoading(false);
  }

  function convertTimestamp(date: Date | undefined, time: string) {
    const arrayTimeSplited = time.split(":");
    date?.setHours(Number(arrayTimeSplited[0]));
    date?.setMinutes(Number(arrayTimeSplited[1]));
    date?.setDate(date.getDate() + 1);

    return date?.getTime()! / 1000; //Converte timestamp de milisegundos para segundos e coloca no horário de Brasília
  }

  function handleDialog() {
    setIsDialogOpen(!isDialogOpen);
    setSelectedGroup("");
  }

  useEffect(() => {
    if (groupsQuery.isSuccess) {
      setGroups(groupsQuery.data);
    }
    // eslint-disable-next-line
  }, [groupsQuery.isSuccess]);

  const options: any = [];
  groupSensors.forEach((item) => {
    options.push({ name: item.name, value: item.id });
  });

  if (groupsQuery.isLoading || reportsQuery.isLoading) {
    return (
      <div className={styles.center}>
        <BarLoader color="#339933" />
      </div>
    );
  }

  const reports = reportsQuery.data as IReport[];
  const Modal = () => {
    return (
      <>
        {isDialogOpen && (
          <Dialog title="Novo Relatório">
            <>
              {loading ? (
                <div className={styles.loader}>
                  <BarLoader color="#339933" />
                </div>
              ) : (
                <form
                  className={`${isDialogOpen ? styles.reportDataExpanded : styles.reportData
                    }`}
                >
                  <Select
                    id="sensor"
                    label="Escolha um sensor"
                    required={true}
                    value={selectedGroup}
                    onChange={setSelectedGroup}
                    options={options}
                  />
                  <br />
                  <div className={styles.date}>
                    <div className={styles.dateInput}>
                      <label htmlFor="startDate">
                        Data inicial<span>*</span>
                      </label>
                      <input
                        type="date"
                        name="startDate"
                        id="startDate"
                        onChange={(e) => setStartDate(e.target.value)}
                        value={startDate}
                        required
                      />
                    </div>
                    <TimePicker
                      label="Horário inicial"
                      value={startTime}
                      changeValue={(val: string) => setStartTime(val)}
                    />
                  </div>

                  <div className={styles.date}>
                    <div className={styles.dateInput}>
                      <label htmlFor="endDate">
                        Data final<span>*</span>
                      </label>
                      <input
                        type="date"
                        name="endDate"
                        id="endDate"
                        onChange={(e) => setEndDate(e.target.value)}
                        value={endDate}
                        required
                      />
                    </div>
                    <TimePicker
                      label="Horário final"
                      value={endTime}
                      changeValue={(val: string) => setEndTime(val)}
                    />
                  </div>
                  {error && <p className={styles.errorDate}>{error}</p>}
                  <br />
                </form>
              )}
              <div className={styles.buttons}>
                <button
                  onClick={handleDialog}
                  type="button"
                  className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  onClick={() =>
                    createReport(
                      startTimestamp,
                      endTimestamp,
                      selectedGroup === "" ? options[0].value : selectedGroup
                    )
                  }
                  className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 "
                >
                  Gerar relatório
                </button>
              </div>
            </>
          </Dialog>
        )}
      </>
    );
  };

  return (
    <>
      <header className={styles.newReport}>
        <button
          onClick={handleDialog}
          type="button"
          className="text-white bg-green-600 hover:bg-green-800 focus:ring-2 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none"
        >
          Novo Relatório +
        </button>
      </header>

      <main className={styles.containerContent}>
        <h2>Relatórios</h2>
        <div className={styles.reportForm}>
          <Modal />
        </div>
        <div>
          {reports.length === 0 && <div className="mt-16">
            <p className="text-[color:var(--dark-grey)]">Nenhum relatório encontrado.</p>
          </div>}
          {reports.map((report, index) => (
            <ReportItem
              report={report}
              groupSensors={groupSensors}
              key={index}
            />
          ))}
        </div>
      </main>
    </>
  );
}

export default Reports;
