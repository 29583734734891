import { BarLoader } from "react-spinners";

import styles from "./styles.module.scss";

import { DateConversion } from "../../utils/dateConvertion";
import { useNavigate } from "react-router-dom";
import { IReport } from "../../interfaces/Report";

type GroupSensors = {
  id: string;
  name: string;
};

function ReportItem({
  report,
  groupSensors,
}: {
  report: IReport;
  groupSensors: GroupSensors[];
}) {
  const navigate = useNavigate();

  function reportName() {
    const group = groupSensors.find((group) => group.id === report.group_id);
    return group?.name;
  }

  function timestampToDate(timestamp: number) {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString("pt-BR");
  }

  return (
    <>
      <div
        className={styles.report}
        onClick={() =>
          report.status === "SUCCESS"
            ? navigate(`/report/${report.id}`, { state: { report, name: reportName() } })
            : {}
        }
      >
        <div className={styles.reportContent}>
          <div className={styles.reportInfo}>
            <h3 className={styles.title}>{`Relatório da ${reportName()}`}</h3>
            <br />
            <p>
              {`Relatório de ${timestampToDate(
                report.start_at
              )} até ${timestampToDate(report.end_at)}`}{" "}
            </p>
            <p className={styles.expiration}>
              Relatório disponível até:{" "}
              {DateConversion.stringDateToLocalDatePlusTimestamp(report.created_at, 86400000)}
            </p>
          </div>
          {report.status === "SUCCESS" ? (
            <div className={styles.reportFinished}>
              <span className="material-symbols-outlined">check_small</span>
              <p>Pronto para consulta</p>
            </div>
          ) : (
            <div className={styles.reportLoading}>
              <p>
                Em processamento...
                <br />
                Isso pode demorar um pouco...
              </p>
              <BarLoader color="#339933" />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
export default ReportItem;
