import { QueryClient, QueryClientProvider } from "react-query";

import "./styles/global.scss";
import { AppRoutes } from "./routes";
import { APIContextCmpnt } from "./contexts/ApiContext";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <APIContextCmpnt>
        <AppRoutes />
      </APIContextCmpnt>
    </QueryClientProvider>
  );
}

export default App;
