import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { isExpired } from "react-jwt";
import { useUser } from "../../hook/useUser";
import { Outlet } from "react-router-dom";

type UserType = {
  uid: string;
} | null;

export const Guard = () => {
  const navigate = useNavigate();
  const user: UserType = useUser();

  useEffect(() => {
    let token = localStorage.getItem("access_token");

    if (!token || isExpired(token)) {
      navigate("/login");
    }
    // eslint-disable-next-line 
  }, [user]);

  return (
    <>
      <Outlet />
    </>
  );
};
