import React, { useEffect, useState } from "react";
import { api } from "../../services/api";
import { useLocation, useNavigate } from "react-router-dom";
import { LogsApibn } from "../../components/LogsApibn";
import BarLoader from "react-spinners/BarLoader";
import { IExecution } from "../../interfaces/Logs";

const removeQuantityToId = 15;
const secondsInTimestamp = 1000 * 30;
const limitOfItems = 20;

export const Executions = () => {
  const { state, pathname, search } = useLocation();
  const navigate = useNavigate();
  const id = pathname.substring(removeQuantityToId);

  const [executions, setExecutions] = useState<IExecution[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [pages, setPages] = useState<number[]>([]);
  const [page, setPage] = useState<number>(parseInt(search.substring(6)));
  const [limit, setLimit] = useState<number>(1);

  const navigatePage = (newPage: number) => {
    if (newPage === page && newPage !== 1) {
      return;
    }
    navigate(`/logs/routines/${id}?page=${newPage}`, {
      replace: true,
      state: state,
    });
    getExecutions(newPage);
  };

  async function getExecutions(newPage: number, interval?: boolean) {
    if (!interval) {
      setLoading(true);
    }
    try {
      const data = await api.get(
        `/logs/routines/${id}/executions?limit=${limitOfItems}&page=${newPage}`
      );

      const results = data.data.result;
      const tempLimit = data.data.pages;
      setLimit(tempLimit);

      if (results.length === 0) {
        setLoading(false);
        return;
      }

      setExecutions(results);
      setPage(newPage);
      setLoading(false);

      const morePages = 3;
      const minimumPage = 1;

      let maxPage = newPage + morePages;
      if (maxPage > tempLimit) {
        maxPage = newPage + (tempLimit - newPage);
      }

      setPages(() => []);
      for (let i = maxPage; i >= newPage - morePages; i--) {
        if (i > minimumPage) setPages((prev) => [...prev, i]);
      }
      setPages((prev) => [...prev, 1].reverse());
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    getExecutions(page);

    const interval = setInterval(() => {
      const actualPage = +window.location.search.replace("?page=", "");
      getExecutions(actualPage, true);
    }, secondsInTimestamp);

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen w-screen">
        <BarLoader className="mb-8 mt-4" color="#339933" />
      </div>
    );
  }

  return (
    <section className="flex flex-col px-4">
      <div className="flex items-center py-8 text-[color:var(--dark-grey)]">
        <span
          className="material-symbols-outlined font-bold cursor-pointer text-md pr-4"
          onClick={() => navigate(-1)}
        >
          arrow_back
        </span>
        <h2>{state["name"]}</h2>
      </div>
      <ul className="inline-flex items-center -space-x-px mb-4">
        {page > 1 && (
          <li onClick={() => navigatePage(page - 1)}>
            <span className="block px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700">
              <span className="sr-only">Anterior</span>
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </span>
          </li>
        )}
        {pages.map((item) => (
          <React.Fragment key={item}>
            <li onClick={() => navigatePage(item)}>
              <span
                className={
                  page === item
                    ? "cursor-pointer z-10 px-3 py-2 leading-tight text-blue-600 border border-blue-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700"
                    : "cursor-pointer px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                }
              >
                {item}
              </span>
            </li>
            {item === 1 && page >= 6 && (
              <span className="font-bold text-xl px-1">...</span>
            )}
          </React.Fragment>
        ))}
        {page < limit && (
          <li onClick={() => navigatePage(page + 1)}>
            <span className="block px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700">
              <span className="sr-only">Próxima</span>
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </span>
          </li>
        )}
      </ul>

      <LogsApibn
        thead={[
          "Status",
          "Mensagem",
          "Início",
          "Duração",
          "Rotina",
          "Rasp ID",
          "Aplicação",
          "Commit Hash",
        ]}
        executions={executions}
        state={state}
      />
    </section>
  );
};
