import { useState, useEffect, useContext } from "react";
import { decodeToken } from "react-jwt";
import { APIContext } from "../contexts/ApiContext";
import { IUser } from "../interfaces/User";

type DecodedToken = {
  uid: string;
};

export function useUser() {
  const { getUsersApi } = useContext(APIContext);
  const [user, setUser] = useState<IUser | null>(null);

  const getUser = async () => {
    let token = localStorage.getItem("access_token");

    if (token && !user) {
      let decodedToken = decodeToken(token) as DecodedToken;

      const data = await getUsersApi(decodedToken["uid"]);
      setUser(data);
    }
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, []);

  return user;
}
