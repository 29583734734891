import { useContext, useState } from "react";
import { Download } from "react-feather";

import styles from "./styles.module.scss";
import { APIContext } from "../../contexts/ApiContext";
import { useLocation, useNavigate } from "react-router-dom";
import { IReport } from "../../interfaces/Report";
import { DateConversion } from "../../utils/dateConvertion";
import { api } from "../../services/api";

export const ReportDetails = () => {
  const { getFileUrlApi } = useContext(APIContext);

  const navigate = useNavigate();
  const { state } = useLocation();
  const { report, name }: { report: IReport; name: string } = state;
  const [page, setPage] = useState<number>(0);

  async function getFile(fileUrl: string | undefined) {
    if (!fileUrl) return;
    try {
      fileUrl = fileUrl.startsWith("/api")
        ? fileUrl.replace("/api", "")
        : fileUrl;

      const fileName: string = fileUrl.replace(/^.*[\\/]/, "");
      const data = await getFileUrlApi(fileUrl);
      const href = URL.createObjectURL(data);

      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    } catch (_) {
      window.alert("Ocorreu um erro ao obter o arquivo/imagem.");
    }
  }

  const handleOpenFile = async () => {
    try {
      const { data, status } = await api.get(report.files![1].url.replace("/api", ""))
      if (status === 200) {
        const newTab = window.open('', "_blank");
        newTab?.document.write(data);
      } else {
        alert('Erro ao obter o arquivo gráfico.');
      }
    } catch (_) {
      alert('Erro ao obter o arquivo gráfico.');
    }
  };

  return (
    <>
      <div className={styles.reportDetails}>
        <div className={styles.header}>
          <span
            className="material-symbols-outlined"
            onClick={() => navigate("/reports")}
          >
            arrow_back
          </span>
          <h2>{name}</h2>
        </div>
        <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200">
          <ul className="flex flex-wrap -mb-px">
            <li className="mr-2">
              <span
                onClick={() => setPage(0)}
                className={
                  page === 0
                    ? "cursor-pointer inline-block p-4 text-green-600 border-b-2 border-green-600 rounded-t-lg"
                    : "cursor-pointer inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300"
                }
              >
                Análise
              </span>
            </li>
            <li className="mr-2">
              <span
                onClick={() => setPage(1)}
                className={
                  page === 1
                    ? "cursor-pointer inline-block p-4 text-green-600 border-b-2 border-green-600 rounded-t-lg"
                    : "cursor-pointer inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300"
                }
              >
                Arquivos
              </span>
            </li>
          </ul>
          {page === 0 ? (
            <div className={styles.analyze}>
              <button
                onClick={handleOpenFile}
                type="button"
                className="text-white bg-green-600 hover:bg-green-800 focus:ring-2 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none"
              >
                Ver Gráfico
              </button>
              <div className={styles.analyzeDetails}>
                <strong>
                  Dados retornados:
                  <span>{report.retrieved_data} leituras</span>
                </strong>
                <strong>
                  Data emissão:
                  <span>
                    {DateConversion.stringDateToLocalDatePlusTimestamp(
                      report.created_at
                    )}
                  </span>
                </strong>
                <strong>
                  Data processamento:
                  <span>
                    {DateConversion.stringDateToLocalDatePlusTimestamp(
                      report.processed_at
                    )}
                  </span>
                </strong>
                <h4>IDs dos sensores:</h4>
                <ul>
                  {report.sensors_ids.map((item: string) => (
                    <li key={item}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
          ) : (
            <section className={styles.files}>
              <div>
                <h2>Arquivo CSV</h2>
                <button
                  onClick={() => getFile(report.files![0].url)}
                  className={styles.downloadButton}
                >
                  <Download />
                  Baixar
                </button>
              </div>
              <div>
                <h2>Gráfico</h2>
                <button
                  onClick={() => getFile(report.files![1].url)}
                  className={styles.downloadButton}
                >
                  <Download />
                  Baixar
                </button>
              </div>
            </section>
          )}
        </div>
      </div>
    </>
  );
};
