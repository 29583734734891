import styles from "./styles.module.scss";
import { useContext, useState } from "react";
import { APIContext } from "../../contexts/ApiContext";
import { Modal } from "../Modal";

type Group = {
  id: string;
  name: string;
  type: string;
  data: {
    set_point: number;
    pre_set_point?: number;
    unit: string;
    range: number[];
  };
};

type ParameterProps = {
  group: Group;
  pre_acclimatization?: boolean;
};

export default function Parameter({
  group,
  pre_acclimatization,
}: ParameterProps) {
  const { putHardwareGroupsApi } = useContext(APIContext);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [content, setContent] = useState(
    pre_acclimatization
      ? (group.data.pre_set_point as number)
      : group.data.set_point
  );

  const handleAction = async () => {
    setIsDialogVisible(false);

    let data = {};

    if (pre_acclimatization) {
      data = { pre_set_point: content };
    } else {
      data = { set_point: content };
    }

    await putHardwareGroupsApi(group.id, data);
  };

  const handleClose = () => {
    setIsDialogVisible(false);

    let data = pre_acclimatization
      ? (group.data.pre_set_point as number)
      : group.data.set_point;

    setContent(data);
  };

  const min = group.data.range[0];
  const max = group.data.range[1];

  return (
    <div className={styles.parameter}>
      {isDialogVisible && <Modal
        header={<h3 className="text-xl font-semibold text-gray-900 ">Alterar parâmetro</h3>}
        body={
          <div className={styles.bodyDialog}>
            <div>
              <label
                htmlFor="content"
                className="block mb-2 text-sm font-medium text-grey-700">Parâmetro ({group.data.unit})</label>
              <input
                type="number"
                id="content"
                className="bg-grey-50 border border-grey-500 text-grey-900 placeholder-grey-700 text-sm rounded-lg focus:ring-grey-500 focus:border-grey-500 block w-full p-2.5"
                placeholder="Valor"
                onChange={(e) => setContent(Number(e.target.value))}
                value={content}
              />
              <p className="mt-2 pl-1 text-xs text-grey-600">Altere o setpoint ideial de {group.name}</p>
              {(content < min || content > max) && <p className="mt-2 text-sm text-red-600">Insira um valor entre [${min}, ${max}]</p>}
            </div>
          </div>
        }
        footer={
          <>
            <button
              onClick={handleClose}
              type="button"
              className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
            >
              Cancelar
            </button>

            <button
              disabled={content < min || content > max}
              onClick={handleAction}
              type="button"
              className="text-white bg-green-600 hover:bg-green-800 focus:ring-2 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none"
            >
              Alterar parâmetro
            </button>
          </>
        }
      />}

      <div>
        <p className={styles.title}>
          {group.name}{" "}
          {pre_acclimatization ? " - Pré Aclimatação" : " - Aclimatação"}
        </p>
        <p>
          {content}
          <span>{group.data.unit}</span>
        </p>
      </div>
      <button
        onClick={() => setIsDialogVisible(true)}
        type="button"
        className="text-white bg-green-600 hover:bg-green-800 focus:ring-2 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none"
      >
        Editar
      </button>
    </div>
  );
}
