interface Props {
  label?: string;
  locked?: boolean;
  click: () => void;
  value: boolean;
}

export const Switch = ({ label, locked = false, click, value }: Props) => {
  return (
    <label
      className={`relative inline-flex items-center ${
        locked ? "cursor-not-allowed" : "cursor-pointer"
      }`}
      onClick={locked ? () => {} : click}
    >
      <div
        className={`w-11 h-6 ${
          locked ? "bg-gray-500" : value ? "bg-green-600" : "bg-red-500"
        } peer-focus:outline-none rounded-full peer peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] ${
          value ? "after:left-[22px]" : "after:left-[2px]"
        } after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5`}
      ></div>
      {label && (
        <span className="ml-3 text-sm font-medium text-gray-900">{label}</span>
      )}
    </label>
  );
};
