import { useNavigate } from "react-router-dom";
import { IExecution, ILog } from "../../interfaces/Logs";
import { DateConversion } from "../../utils/dateConvertion";
import { AlertTriangle, Check, Loader, X } from "react-feather";

interface Props {
  thead: string[];
  logs?: ILog[];
  executions?: IExecution[];
  state?: any;
}

export const LogsApibn = ({
  thead,
  logs = [],
  executions = [],
  state,
}: Props) => {
  const navigate = useNavigate();

  const iconStatus = (status: string) => {
    switch (status) {
      case "warning":
        return <AlertTriangle color="var(--yellow)" />;
      case "pending":
        return <Loader color="var(--blue)" />;
      case "failed":
        return <X color="var(--red)" />;
      case "success":
        return <Check color="var(--green)" />;
      default:
        return <></>;
    }
  };

  function formatMinutes(value: number) {
    const minutes = Math.floor(value / 60);
    const seconds = Math.floor(value % 60);

    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');

    return `0:${formattedMinutes}:${formattedSeconds}`;
  }

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left text-gray-500">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            {thead.map((item) => (
              <th key={item} scope="col" className="px-6 py-3">
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {logs.map((item, index) => (
            <tr
              onClick={() =>
                navigate("/logs/routines/" + item.id + "?page=1", {
                  state: item,
                })
              }
              key={item.id}
              className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"
                } hover:bg-gray-200 ease-in duration-300 hover:cursor-pointer border-b`}
            >
              <th
                scope="row"
                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
              >
                {item.name}
              </th>
              <td className="px-6 py-4">{item.application}</td>
              <td className="px-6 py-4">{item.rasp_id}</td>
              <td className="px-6 py-4">{item.description}</td>
            </tr>
          ))}
          {executions.map((item, index) => (
            <tr
              key={item.id}
              className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"
                } hover:bg-gray-200 ease-in duration-300 hover:cursor-pointer border-b`}
              onClick={() =>
                navigate(
                  `/logs/routines/${item.routine}/executions/${item.id}`,
                  { state: [state, item] }
                )
              }
            >
              <td className="px-6 py-4" title={item.status}>
                {iconStatus(item.status)}
              </td>
              <td className="px-6 py-4" title={item.message}>
                {item.message}
              </td>
              <td className="px-6 py-4">
                {DateConversion.stringDateToLocalDatePlusTimestamp(
                  item.start_at
                )}
              </td>
              <td className="px-6 py-4">{item.duration ? formatMinutes(item.duration) : '-'}</td>
              <td className="px-6 py-4">{state["name"]}</td>
              <td className="px-6 py-4">{state["rasp_id"]}</td>
              <td className="px-6 py-4">{state["application"]}</td>
              <td className="px-6 py-4">{item.commit_hash}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}; 
