import React, { useEffect, useState, useContext } from "react";
import Drawer from "../Drawer";
import styles from "./styles.module.scss";
import { pages } from "../../routes";
import NotificationMenu from "../NotificationMenu";
import { APIContext } from "../../contexts/ApiContext";
import { IGreenHouse } from "../../interfaces/GreenHouse";

const Header = () => {
  const { getGreenHouseApi } = useContext(APIContext);
  const [greenHouse, setGreenHouse] = useState({} as IGreenHouse);

  async function getGreenHouse() {
    const data = await getGreenHouseApi();

    setGreenHouse(data);
  }

  useEffect(() => {
    getGreenHouse();
    // eslint-disable-next-line
  }, []);

  return (
    <header className={styles.header}>
      <Drawer items={pages} />
      <h1>{greenHouse.name}</h1>
      <div className={styles.divider}></div>
      <NotificationMenu />
    </header>
  );
};

export default Header;
