import { createContext, ReactNode, useState } from "react";
import { api } from "../services/api";
import { IActuator } from "../interfaces/Actuator";
import { IConfig } from "../interfaces/Config";
import { IGreenHouse } from "../interfaces/GreenHouse";
import { IReport } from "../interfaces/Report";
import { ITank } from "../interfaces/Tank";
import { IAverage } from "../interfaces/Average";
import { IUser } from "../interfaces/User";

type APIContextProps = {
  children: ReactNode;
};

type APIContextState = {
  error: string | null;
  setError: React.Dispatch<React.SetStateAction<string | null>>;
  getDynamicUrlApi: (url: string) => Promise<any>;
  getFileUrlApi: (url: string) => Promise<any>;
  getGreenHouseApi: () => Promise<IGreenHouse>;
  getTankApi: () => Promise<ITank>;
  getAccountTokenApi: (username: string, password: string) => Promise<any>;
  getConfigApi: () => Promise<IConfig>;
  getGroupsApi: () => Promise<any[]>;
  getReportsApi: () => Promise<IReport[]>;
  getActuatorsApi: () => Promise<IActuator[]>;
  getUsersApi: (token: string) => Promise<IUser | null>;
  getReadsAveragesApi: (groupId: string) => Promise<any>;
  getReadsAllAveragesApi: (
    groupId: string,
    startAt: number,
    endAt: number,
    interval?: number
  ) => Promise<any>;
  putHardwareGroupsApi: (id: string, body: {}) => Promise<void>;
  putActuatorsApi: (id: string, value: boolean | number) => Promise<void>;
  putConfigApi: (config: IConfig) => Promise<void>;
  putStateApi: (state: string) => Promise<void>;
  postReportApi: (
    groupId: string,
    startAt: number,
    endAt: number
  ) => Promise<void>;
};

export const APIContext = createContext<APIContextState>(null!);

export const APIContextCmpnt = ({ children }: APIContextProps) => {
  const [error, setError] = useState<string | null>(null);

  const getDynamicUrlApi = async (url: string) => {
    try {
      const { data, status } = await api.get(url, {
        headers: {
          rasp_id: "master-demo",
        },
      });

      if (status >= 200 && status < 300) {
        return data;
      }
    } catch (_) {
      // setError("Erro ao obter URL dinâmica.");
    }
  };

  const getFileUrlApi = async (url: string) => {
    try {
      const { data, status } = await api.get(url, {
        responseType: "blob",
      });

      if (status >= 200 && status < 300) {
        return data;
      }
    } catch (_) {
      // setError("Erro ao obter o arquivo.");
    }
  };

  const getGreenHouseApi = async (): Promise<IGreenHouse> => {
    try {
      const { data, status } = await api.get("/greenhouse/");

      if (status >= 200 && status < 300) {
        return data as IGreenHouse;
      }
    } catch (_) {
      // setError("Erro ao inicializar o aplicativo.");
    }

    return {} as IGreenHouse;
  };

  const getTankApi = async (): Promise<ITank> => {
    try {
      const { data, status } = await api.get("/hardware/groups/tank");

      if (status >= 200 && status < 300) {
        return data as ITank;
      }
    } catch (_) {
      // setError("Erro ao obter informações do tanque.");
    }

    return {} as ITank;
  };

  const getAccountTokenApi = async (
    username: string,
    password: string
  ): Promise<any> => {
    try {
      const { data, status } = await api.get("/account/token/", {
        auth: {
          username,
          password,
        },
        params: {
          grant_type: "password",
        },
      });

      if (status >= 200 && status < 300) {
        return data;
      }
    } catch (_) {
      // setError("Erro ao fazer login.");
    }

    return null;
  };

  const getConfigApi = async (): Promise<IConfig> => {
    try {
      const { data, status } = await api.get("/hardware/config/");
      if (status >= 200 && status < 300) {
        return data as IConfig;
      }
    } catch (_) {
      // setError("Erro ao obter configuração do hardware.");
    }

    return {} as IConfig;
  };

  const getGroupsApi = async (): Promise<any[]> => {
    try {
      const { data, status } = await api.get("/hardware/groups/");
      if (status >= 200 && status < 300) {
        return data as any[];
      }
    } catch (_) {
      // setError("Erro ao obter os grupos do hardware.");
    }

    return [];
  };

  const getReportsApi = async (): Promise<IReport[]> => {
    try {
      const { data, status } = await api.get("/reports/");

      if (status >= 200 && status < 300) {
        const reports = (await data) as IReport[];
        return reports.reverse();
      }
    } catch (_) {
      // setError("Erro ao obter relatórios.");
    }

    return [];
  };

  const getActuatorsApi = async (): Promise<IActuator[]> => {
    try {
      const { data, status } = await api.get("/hardware/actuators/");
      if (status >= 200 && status < 300) {
        return data as IActuator[];
      }
    } catch (_) {
      // setError("Erro ao obter os atuadores.");
    }

    return [];
  };

  const getUsersApi = async (token: string): Promise<IUser | null> => {
    try {
      const { data, status } = await api.get(`/users/${token}/`);

      if (status >= 200 && status < 300) {
        return data as IUser;
      }
    } catch (_) {
      // setError("Erro ao obter os usuários.");
    }

    return null;
  };

  const getReadsAveragesApi = async (groupId: string): Promise<any> => {
    try {
      const { data, status } = await api.get(
        `/hardware/reads/averages/${groupId}?latest=true`
      );

      if (status >= 200 && status < 300) {
        return data as any;
      }
    } catch (_) {
      // setError("Erro ao obter as médias das leituras.");
    }

    return {} as IAverage;
  };

  const getReadsAllAveragesApi = async (
    groupId: string,
    startAt: number,
    endAt: number,
    interval: number = 30
  ) => {
    try {
      const { data, status } = await api.get(
        `/hardware/reads/averages/${groupId}?start_at=${startAt}&end_at=${endAt}&interval=${interval}&latest=false`
      );
      if (status >= 200 && status < 300) {
        return data;
      }
    } catch (_) {
      // setError("Erro ao obter todas as médias de leituras.");
    }
  };

  const putHardwareGroupsApi = async (id: string, body: {}) => {
    try {
      await api.put("/hardware/groups/", [
        {
          id,
          data: body,
        },
      ]);
    } catch (_) {
      setError("Erro ao atualizar o grupo do hardware.");
    }
  };

  const putConfigApi = async (config: IConfig) => {
    try {
      await api.put("/hardware/config/", config);
    } catch (_) {
      setError("Erro ao atualizar a configuração.");
    }
  };

  const putStateApi = async (state: string) => {
    try {
      await api.put("/hardware/config/", {
        state,
      });
    } catch (_) {
      setError("Erro ao atualizar o estado.");
    }
  };

  const putActuatorsApi = async (
    id: string,
    value: boolean | number
  ): Promise<void> => {
    try {
      await api.put("/hardware/actuators/", [
        {
          id,
          current_value: value,
        },
      ]);
    } catch (_) {
      setError("Erro ao atualizar os atuadores.");
      throw Error();
    }
  };

  const postReportApi = async (
    groupId: string,
    startAt: number,
    endAt: number
  ) => {
    try {
      await api.post("/reports/", {
        group_id: groupId,
        start_at: startAt,
        end_at: endAt,
      });
    } catch (_) {
      setError("Erro ao criar o relatório.");
    }
  };

  const initState: APIContextState = {
    error,
    setError,
    getFileUrlApi,
    getAccountTokenApi,
    getUsersApi,
    getReadsAveragesApi,
    getDynamicUrlApi,
    getReportsApi,
    getGreenHouseApi,
    getConfigApi,
    getTankApi,
    getActuatorsApi,
    getGroupsApi,
    getReadsAllAveragesApi,
    putHardwareGroupsApi,
    putActuatorsApi,
    putConfigApi,
    putStateApi,
    postReportApi,
  };

  return (
    <APIContext.Provider value={initState}>{children}</APIContext.Provider>
  );
};
