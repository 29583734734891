interface Props {
    header: JSX.Element;
    body: JSX.Element;
    footer: JSX.Element;
}

export const Modal = ({ header, body, footer }: Props) => {
    return (
        <section className="flex justify-center items-center h-screen w-screen bg-[#00000047] fixed top-0 left-0 overflow-x-hidden overflow-y-auto z-50">
            <div id="defaultModal" tabIndex={1} aria-hidden="false" className="p-4">
                <div className="relative w-full max-w-2xl max-h-full">
                    <div className="relative bg-white rounded-lg shadow">
                        <div className="flex items-start justify-between p-4 border-b rounded-t">
                            {header}
                        </div>

                        <div className="p-6 space-y-6">
                            {body}
                        </div>

                        <div className="flex justify-center items-center p-6 space-x-2 border-t border-gray-200 rounded-b">
                            {footer}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
