import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import { isExpired } from "react-jwt";

const onRequest = async (config: AxiosRequestConfig) => {
  try {
    let accessToken = localStorage.getItem("access_token");

    if (config.url?.includes("/account")) {
      return config;
    }

    if (config.method === "post" && config.url === "/users/") {
      return config;
    }

    if (isExpired(accessToken as string)) {
      accessToken = await reloadTokens();
    }
    if (config.headers) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }

    return config;
  } catch (_) {}
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const reloadTokens = async () => {
  const refreshToken = localStorage.getItem("refresh_token");

  try {
    const response = await axios.post(`/api/account/token/refresh/`, {
      refresh: refreshToken,
    });

    const { access_token, refresh_token } = response.data;

    localStorage.setItem("access_token", access_token);
    localStorage.setItem("refresh_token", refresh_token);

    return access_token;
  } catch (_error) {
    return Promise.reject(_error);
  }
};

const setupInterceptorsTo = (axiosInstance: AxiosInstance): AxiosInstance => {
  axiosInstance.interceptors.request.use(onRequest as any, onRequestError);
  return axiosInstance;
};

export const api = setupInterceptorsTo(
  axios.create({
    baseURL: "/api",
    headers: {
      "Content-Type": "application/json",
    },
  })
);
