import { useContext, useEffect, useState } from "react";
import { Bell, X } from "react-feather";

import useDropdownMenu from "../../utils/useDropdownMenu";

import styles from "./styles.module.scss";
import { ITank } from "../../interfaces/Tank";
import { IConfig } from "../../interfaces/Config";
import { IActuator } from "../../interfaces/Actuator";
import { APIContext } from "../../contexts/ApiContext";
import { Modal } from "../Modal";

type Notification = {
  message: string;
  color?: string;
};

interface IActuatorActive {
  id: string;
  current: boolean | number;
}

function NotificationMenu() {
  const [notificationsPopUp, setNotificationsPopUp] = useState<Notification[]>(
    []
  );
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const { buttonProps, isOpen, setIsOpen } = useDropdownMenu(1);
  const { getTankApi, getConfigApi, getActuatorsApi } = useContext(APIContext);
  const [actuators, setActuators] = useState<IActuatorActive[]>([]);

  function addNotification(notification: Notification) {
    setNotifications((current) => [...current, notification]);
    setNotificationsPopUp((current) => [...current, notification]);
  }

  function sumDaysToDate(oldDate: string, sum: number) {
    const date = new Date(oldDate);
    date.setDate(date.getDate() + sum);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${day}/${month}/${year}`;
  }

  async function listActiveActuators(allActuators: IActuator[]) {
    const uniqueNameActuators: IActuatorActive[] = [];
    allActuators.forEach((item) => {
      if (
        (item.subtype === "onoff" && item.current_value === 1) ||
        item.current_value === true
      ) {
        uniqueNameActuators.push({
          id: item.name,
          current: item.current_value,
        });
      }
    });

    setActuators(uniqueNameActuators);
  }

  async function getAllValues() {
    const tank = await getTankApi();
    const config = await getConfigApi();
    const actuators = await getActuatorsApi();

    const actuatorValve = actuators.find(
      (item: IActuator) => item.id === "valvula-enchimento"
    ) as IActuator;

    await listActiveActuators(actuators);

    if (config.state !== "pre_acclimatization") {
      setNotifications([
        {
          message: `Próxima fertirrigação de orquídeas: ${sumDaysToDate(
            config.last_on_orchid,
            16
          )}`,
        },
      ]);
    } else {
      setNotifications([]);
    }

    setTankNotifications(tank, config, actuatorValve);
    setEmergencyNotifications(config);
  }

  useEffect(() => {
    getAllValues();
    const interval = setInterval(() => {
      getAllValues();
    }, 60000);

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  function setTankNotifications(
    tank: ITank,
    config: IConfig,
    valve: IActuator
  ) {
    if (tank.state === "minimo" && valve.current_value === false) {
      addNotification({
        message:
          "O tanque de fertirrigação está no nível mínimo. Esteja atento para o reabastecimento.",
      });
    } else if (tank.state === "critico" && valve.current_value === false) {
      addNotification({
        message:
          "O tanque de fertirrigação está no nível critico. É necessário o reabastecimento imediato do tanque.",
      });
    } else if (
      tank.state === "cheio" &&
      valve.current_value === false &&
      config.flag_fert === false
    ) {
      addNotification({
        message:
          "O tanque de fertirrigação está cheio e pronto para receber a mistura.",
      });
    }
  }

  function setEmergencyNotifications(config: IConfig) {
    if (config.emergency?.storm === true) {
      addNotification({ message: "Rajadas de vento muito fortes." });
    }
    if (config.emergency?.sun === true) {
      addNotification({ message: "Nível de radiação solar elevado." });
    }
  }

  function handleDialogClose() {
    setNotificationsPopUp([]);
  }

  const Empty = () => (
    <>
      <img src="/images/empty-box.png" alt="" />
      <p className={styles.noNotifications}>Sem notificações</p>
    </>
  );

  return (
    <div className={styles.container}>
      <button {...buttonProps}>
        <div
          className={`${notifications.length >= 1
              ? notifications[0].message.includes(
                "Próxima fertirrigação de orquídeas"
              ) && notifications.length === 1
                ? styles.notificationEmpty
                : styles.notificationAlert
              : styles.notificationEmpty
            }`}
        >
          <span>
            {notifications.length > 0 &&
              notifications[0].message.includes(
                "Próxima fertirrigação de orquídeas"
              )
              ? notifications.length - 1
              : notifications.length}
          </span>
        </div>
        <Bell className={styles.bell} />
      </button>
      <div
        className={`${styles.notification} ${isOpen ? styles.open : ""} ${notifications.length === 0 ? styles.empty : ""
          }`}
        role="menu"
      >
        <header>
          <h2>Notificações</h2>
          <button
            className={styles.closeButton}
            onClick={() => setIsOpen(false)}
          >
            <X color="#000" />
          </button>
        </header>
        <div className={`${styles.content}`}>
          <aside>
            {notifications.map((notification) => (
              <p key={notification.message} className={styles.notificationCard}>
                {notification.message}
              </p>
            ))}

            {notifications.length === 0 ? <Empty /> : null}

            <h3 className={styles.h3}>Atuadores ativos</h3>
            {actuators.map((notification) => {
              if (
                typeof notification.current === "boolean" &&
                notification.current === true
              ) {
                return (
                  <p key={notification.id} className={styles.actuatorCard}>
                    {notification.id}
                  </p>
                );
              } else if (
                typeof notification.current === "number" &&
                notification.current > 0
              ) {
                return (
                  <p key={notification.id} className={styles.actuatorCard}>
                    {notification.id}
                  </p>
                );
              }
              return null;
            })}
          </aside>
        </div>
      </div>
      <div className={isOpen ? styles.mask : ""}></div>

      {notificationsPopUp.length > 1 && (
        <Modal
          header={
            <h3 className="text-xl font-semibold text-gray-900 ">
              ⚠️ Alerta ⚠️{" "}
            </h3>
          }
          body={
            <div className="dialog">
              {notifications.map((item, index) => {
                if (index !== 0) {
                  return (
                    <p className="text-gray-500 py-2" key={item.message}>
                      {item.message}
                    </p>
                  );
                }
                return null;
              })}
            </div>
          }
          footer={
            <button
              onClick={handleDialogClose}
              type="button"
              className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
            >
              Fechar
            </button>
          }
        />
      )}
    </div>
  );
}

export default NotificationMenu;
