import { useContext, useEffect, useState } from "react";
import styles from "./dashboard.module.scss";
import AverageCard from "../../components/AverageCard";
import WeatherStationCard from "../../components/WeatherStationCard";
import { IGroup } from "../../interfaces/Group";
import { APIContext } from "../../contexts/ApiContext";

function Cards() {
  const [groups, setGroups] = useState([] as IGroup[]);
  const { getGroupsApi } = useContext(APIContext);

  async function getGroups() {
    const data = await getGroupsApi();

    setGroups(data);
  }

  useEffect(() => {
    getGroups();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <section className={styles.cards}>
        {groups
          .filter(
            (group) =>
              group.type === "sensors" && group.subtype !== "weather_station"
          )
          .map((group, index) => <AverageCard key={index} group={group} />)}
      </section>
      <h2 className={`${styles.title} title`}>Estação Meteorológica</h2>
      <section className={styles.cards}>
        {groups
          .filter((group) => group.subtype === "weather_station")
          .map((group, index) => {
            if (group.id === "rtWindSpeed") {
              return null;
            }

            return <WeatherStationCard key={index} group={group} />;
          })}
      </section>
    </>
  );
}

function Dashboard() {
  return (
    <>
      <img className={styles.image} src={'/images/sponsors.png'} alt="logo" />
      <h2 className={`${styles.title} title`}>Leituras</h2>
      <Cards />
    </>
  );
}

export default Dashboard;
