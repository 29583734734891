import React from "react";

import styles from "./styles.module.scss";

type SelectType = {
  id: string;
  label: string;
  required: boolean;
  value: string;
  onChange: React.Dispatch<React.SetStateAction<string>>;
  options: { value: string; name: string }[];
};

export const Select = ({
  id,
  label,
  required,
  value,
  onChange,
  options,
}: SelectType) => {
  return (
    <div className={styles.selectContent}>
      <label htmlFor={id} className={styles.label}>
        {label}
        <span>*</span>
      </label>
      <select
        onChange={(e) => onChange(e.target.value)}
        required={required}
        name={id}
        id={id}
        className={styles.select}
        value={value}
        placeholder="Selecione um"
      >
        {options.map((opt) => (
          <option key={opt.value} value={opt.value}>
            {opt.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
