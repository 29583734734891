import React, { useEffect } from "react";
import { AlertTriangle, Check, Loader, X } from "react-feather";
import { useLocation, useNavigate } from "react-router-dom";
import { api } from "../../services/api";
import { DateConversion } from "../../utils/dateConvertion";
import { BarLoader } from "react-spinners";
import { IExecution, ILog, IMessage, IStep } from "../../interfaces/Logs";

import styles from "./styles.module.scss";

export const DetailedLogs = () => {
  const { pathname, state, search } = useLocation();
  const navigate = useNavigate();
  const [step, setStep] = React.useState<string>("");
  const [steps, setSteps] = React.useState<IStep[]>([]);
  const [msgs, setMsgs] = React.useState<IMessage[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  async function getSteps() {
    setLoading(true);
    try {
      const data = await api.get(`${pathname}/steps`);
      setSteps(data.data);
      if (data.data.length > 0) {
        const tempStep = search.length === 0 ? data.data[0].id : search.substring(6);
        setStep(tempStep);
        navigate(`${pathname}?step=${tempStep}`, { replace: true, state: state });
        const dataMsg = await api.get(`${pathname}/steps/${tempStep}/logs`);
        setMsgs(dataMsg.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  async function getMessages(id: string) {
    try {
      navigate(`${pathname}?step=${id}`, { replace: true, state: state });
      setLoading(true);
      setStep(id);

      const data = await api.get(`${pathname}/steps/${id}/logs`);
      setMsgs(data.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    getSteps();
    // eslint-disable-next-line
  }, []);

  if (loading && steps.length === 0) {
    return (
      <div className="flex justify-center items-center h-screen w-screen">
        <BarLoader className="mb-8 mt-4" color="#339933" />
      </div>
    );
  }

  if (steps.length === 0) {
    return (
      <section className="flex flex-col mt-40 w-screen items-center justify-center">
        <h2 className="font-medium text-sm mb-4">
          Ops... Parece que não há mensagens de logs aqui.
        </h2>
      </section>
    );
  }

  const iconStatus = (status: string) => {
    switch (status) {
      case "warning":
        return <AlertTriangle color="white" />;
      case "pending":
        return <Loader color="var(--blue)" />;
      case "failed":
        return <X color="white" />;
      case "error":
        return <X color="white" />;
      case "success":
        return <Check color="white" />;
      case "info":
        return <Check color="white" />;
      default:
        return <></>;
    }
  };

  const statusBg = (status: string) => {
    switch (status) {
      case "warning":
        return "bg-yellow-500";
      case "pending":
        return "bg-white";
      case "failed":
        return "bg-red-500";
      case "error":
        return "bg-red-500";
      case "success":
        return "bg-green-500";
      case "info":
        return "bg-green-500";
      default:
        return "bg-white";
    }
  };

  const logs = state[0] as ILog;
  const execution = state[1] as IExecution;
  return (
    <section className="flex flex-col items-center justify-center px-4 pb-4">
      <div className="flex items-center py-8 text-[color:var(--dark-grey)] self-start">
        <span
          className="material-symbols-outlined font-bold cursor-pointer text-md pr-4"
          onClick={() => navigate(-1)}
        >
          arrow_back
        </span>
        <h2>Detalhes da execução</h2>
      </div>
      <div className="flex flex-wrap mb-16 w-full gap-x-1 gap-y-2">
        <div className="flex px-4 py-2 border rounded bg-green-100">
          <span className="text-gray-600 mr-2 font-bold">Nome:</span>
          <span className="text-gray-800">{logs.name}</span>
        </div>
        <div className="flex px-4 py-2 border rounded bg-green-100">
          <span className="text-gray-600 mr-2 font-bold">RaspID:</span>
          <span className="text-gray-800">{logs.rasp_id}</span>
        </div>
        <div className="flex px-4 py-2 border rounded bg-green-100">
          <span className="text-gray-600 mr-2 font-bold">Descrição:</span>
          <span className="text-gray-800">{logs.description}</span>
        </div>
        <div className="flex px-4 py-2 border rounded bg-green-100">
          <span className="text-gray-600 mr-2 font-bold">Aplicação:</span>
          <span className="text-gray-800">{logs.application}</span>
        </div>
        <div className="flex px-4 py-2 border rounded bg-green-100">
          <span className="text-gray-600 mr-2 font-bold">Criação:</span>
          <span className="text-gray-800">
            {DateConversion.stringDateToLocalDatePlusTimestamp(logs.create_at)}
          </span>
        </div>
        <div className="flex px-4 py-2 border rounded bg-green-100">
          <span className="text-gray-600 mr-2 font-bold">Rotina:</span>
          <span className="text-gray-800">{execution.routine}</span>
        </div>
        <div className="flex px-4 py-2 border rounded bg-green-100">
          <span className="text-gray-600 mr-2 font-bold">Início:</span>
          <span className="text-gray-800">
            {DateConversion.stringDateToLocalDatePlusTimestamp(
              execution.start_at
            )}
          </span>
        </div>
        <div className="flex px-4 py-2 border rounded bg-green-100">
          <span className="text-gray-600 mr-2 font-bold">Commit Hash:</span>
          <span className="text-gray-800">{execution.commit_hash}</span>
        </div>
      </div>
      <ol
        className={`${styles.steps} pb-10 w-full lg:w-fit flex flex-wrap justify-center items-center`}
      >
        {steps.map((item) => (
          <li
            key={item.id}
            className={`cursor-pointer w-fit flex items-center ${item !== steps[steps.length - 1] &&
              "text-blue-600 lg:after:content-normal lg:after:w-12 lg:after:h-1 lg:after:border-b lg:after:border-blue-100 lg:after:border-4 lg:after:inline-block"
              }`}
            onClick={() => {
              if (step !== item.id) getMessages(item.id);
            }}
          >
            <div className="flex flex-col items-center justify-center">
              <span
                className={`${item.id === step ? "text-blue-600" : "text-gray-700"
                  } text-center text-sm md:text-base`}
              >
                {item.name}
              </span>
              <span
                title={item.status}
                className={`mt-2 flex items-center justify-center w-10 h-10 border-2 ${item.id === step ? "border-blue-600" : "border-gray-600"
                  } rounded-full shrink-0 ${statusBg(item.status)}`}
              >
                {iconStatus(item.status)}
              </span>
            </div>
          </li>
        ))}
      </ol>
      {(loading) ?
        <div className="flex justify-center items-center">
          <BarLoader className="mb-8 mt-4" color="#339933" />
        </div>
        :
        <div className="w-full">
          {msgs.length > 0 ? (
            <div id="accordion-collapse" data-accordion="collapse">
              {msgs.reverse().map((item, index) => (
                <React.Fragment key={index}>
                  <div
                    className={`flex items-center border-b border-x border-t mt-4`}
                  >
                    <div
                      title={item.status}
                      className={`${statusBg(
                        item.status
                      )} h-8 w-8 flex items-center justify-center`}
                    >
                      <div className="h-5 w-5 flex items-center justify-center rounded-full">
                        {iconStatus(item.status)}
                      </div>
                    </div>
                    <p className="flex-grow pl-2">
                      {item.content.substring(0, 20)}...
                    </p>
                    <div className="flex items-center pr-2">
                      <p className="ml-auto pr-2">
                        {DateConversion.stringDateToLocalDatePlusTimestamp(
                          item.timestamp
                        )}
                      </p>
                    </div>
                  </div>
                  <pre className="bg-gray-100 p-4 overflow-auto whitespace-pre overflow-ellipsis leading-140%">
                    {item.content}
                  </pre>
                </React.Fragment>
              ))}
            </div>
          ) : (
            <div className="w-screen flex justify-center mt-8">
              <h2 className="font-medium text-sm mb-4">
                Não há logs nessa branch.
              </h2>
            </div>
          )}
        </div>}
    </section>
  );
};
