import { useContext, useState } from "react";
import { BarLoader } from "react-spinners";
import styles from "./actuator.module.scss";
import { APIContext } from "../../contexts/ApiContext";
import { Switch } from "../Switch";
import { useNavigate } from "react-router-dom";

type ActuatorType = {
  id: string;
  name: string;
  current_value: number | boolean;
  subtype: string;
  adress: {
    rasp_id: string;
    pin: number;
  };
  end_sensors: {
    id: number;
  }[];
};

const Actuator = ({
  actuator,
  locked,
  refetch,
}: {
  actuator: ActuatorType;
  locked: boolean;
  refetch: () => Promise<void>;
}) => {
  const navigate = useNavigate();
  const { putActuatorsApi } = useContext(APIContext);

  const [isLoading, setIsLoading] = useState(false);

  const handleAction = async (newValue: number | boolean) => {
    setIsLoading(true);

    await putActuatorsApi(actuator.id, newValue)
      .then(async () => {
        refetch().finally(() => setIsLoading(false));
      })
      .catch((_) => setIsLoading(false));
  };

  let action;
  let actionValue: string = "";

  if (actuator.subtype === "onoff") {
    action = (
      <Switch
        click={() => handleAction(!actuator.current_value)}
        locked={locked}
        value={actuator.current_value as boolean}
      />
    );
  }
  if (actuator.subtype === "cyclic") {
    let buttonBackgroundValue = typeof actuator.current_value === "boolean" ? actuator.current_value ? 1 : 0 : actuator.current_value;
    let steps = actuator.end_sensors.length - 1;
    let stepIndicator = 100 / steps;
    let value = stepIndicator * (actuator.current_value as number);
    actionValue = Math.round(value) + "% Aberto";

    action = (
      <div className={styles.buttonGroup}>
        {actuator.end_sensors.map((item, buttonIndex) => {
          return (
            <button
              disabled={locked}
              key={buttonIndex}
              className={`${styles.button} ${buttonBackgroundValue === buttonIndex ? styles.active : ""
                }`}
              data-value={buttonIndex}
              onClick={(e: React.SyntheticEvent<HTMLButtonElement>) => {
                e.preventDefault();
                let newValue: number = Number(e.currentTarget.dataset["value"]);
                handleAction(newValue);
              }}
            >
              <div className={styles.stepIndicator}>
                {[...Array(steps)].map((v, index) => {
                  return (
                    <span
                      key={index}
                      style={{
                        width: `${stepIndicator}%`,
                        left: `${stepIndicator * index}%`,
                      }}
                      className={`${styles.step} ${index < buttonIndex ? styles.active : ""
                        }`}
                    >
                      {index === 0 ? null : (
                        <span className={styles.divider}></span>
                      )}
                    </span>
                  );
                })}
              </div>
              {buttonIndex === 0 ? null : (
                <span className={styles.divider}></span>
              )}
            </button>
          );
        })}
      </div>
    );
  }

  return (
    <div className={styles.actuator}>
      {isLoading ? (
        <div className={styles.isLoading}>
          <BarLoader color="#339933" />
        </div>
      ) : null}

      <div className={styles.info}>
        <h3 className={styles.title}> {actuator.name}</h3>
      </div>
      <div className={styles.action}>
        {action}
        <p>{actionValue}</p>
      </div>
      <div
        onClick={() =>
          navigate(`/actuators/${actuator.id}`, { state: actuator })
        }
        className={styles.actuatorDetails}
      >
        <span className="material-symbols-outlined">arrow_forward_ios</span>
      </div>
    </div>
  );
};

export default Actuator;
