import { useContext, useEffect, useState } from "react";
import Actuator from "../../components/Actuator";
import styles from "./controls.module.scss";
import { BarLoader } from "react-spinners";
import { useQuery } from "react-query";
import { APIContext } from "../../contexts/ApiContext";
import { IActuator } from "../../interfaces/Actuator";
import { IConfig, IRoutines } from "../../interfaces/Config";
import { Modal } from "../../components/Modal";
import { LongSwitch } from "../../components/LongSwitch";

export const titles: { [key: string]: string } = {
  ferti: "Fertirrigação",
  light: "Iluminação artificial",
  irrigation: "Irrigação",
  nebulization: "Nebulização",
  storm: "Emergência ventos fortes",
  sun: "Emergência solar",
  leaf_wetness: "Molhamento foliar",
  co2: "Enriquecemento CO2",
  aluminet: "Aluminet",
  fuzzy: "Fuzzy"
};

function Actuators({
  routines,
  fuzzy,
}: {
  routines: IRoutines;
  fuzzy: boolean;
}) {
  const { getActuatorsApi } = useContext(APIContext);

  const actuatorsQuery = useQuery({
    queryKey: ["actuators"],
    queryFn: () => getActuatorsApi(),
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    refetchInterval: 4000,
  });

  const actuators = actuatorsQuery.data || ([] as IActuator[]);

  if (actuatorsQuery.isLoading) {
    return (
      <div className={styles.center}>
        <BarLoader color="#339933" />
      </div>
    );
  }



  return (
    <div className={styles.actuators}>
      {actuators.map((actuator: IActuator, index: number) => {
        let lockedActuator: boolean = false;

        if (
          [
            "aquecedor",
            "cortina",
            "janela",
            "exaustor",
            "forro",
            "aluminet",
            "bomba-parede-umida",
          ].includes(actuator.group_id)
        ) {
          lockedActuator = fuzzy;
        } else if (actuator.routines != null) {
          Object.entries(routines).forEach(([key, value]) => {
            if (actuator.routines?.includes(key) && value) {
              lockedActuator = true;
            }
          });
        }

        if (actuator.id !== "security_lock") {
          return (
            <Actuator
              key={index}
              actuator={actuator}
              locked={lockedActuator}
              refetch={async () => {
                await actuatorsQuery.refetch();
              }}
            />
          );
        }

        return null;
      })}
    </div>
  );
}

function Controls() {
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [config, setConfig] = useState<IConfig | null>(null);
  const [tempKey, setTempKey] = useState<string>("");
  const [expanded, setExpanded] = useState<boolean>(false);
  const { getConfigApi, putConfigApi } = useContext(APIContext);

  const modesQuery = useQuery({
    queryKey: ["modes"],
    queryFn: () => getConfigApi(),
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    refetchInterval: 3000,
  });

  useEffect(() => {
    if (modesQuery.isSuccess) {
      setConfig(modesQuery.data);
    }
    // eslint-disable-next-line
  }, [modesQuery.data]);

  const handleChangeMode = (key: string) => {
    setIsDialogVisible(true);
    setTempKey(key);
  };

  const handleClose = async () => {
    setIsDialogVisible(false);
    setTempKey("");
  };

  const handleConfirmation = async () => {
    setIsLoading(true);
    setIsDialogVisible(false);

    window.scrollTo(0, 0);

    setConfig((prevState: any) => {
      const newState =
        tempKey === "fuzzy"
          ? { ...prevState, fuzzy: !prevState?.fuzzy }
          : {
            ...prevState,
            routines: {
              ...prevState?.routines!,
              [tempKey]: !prevState?.routines[tempKey]!,
            },
          };
      putConfigApi(newState!).finally(() => {
        modesQuery.refetch().finally(() => {
          setTempKey("");
          setIsLoading(false);
        });
      });
      return newState;
    });
  };


  function ConfirmationDialog() {
    return (
      <Modal
        header={
          <h3 className="text-xl font-semibold text-gray-900">Confirmação</h3>
        }
        body={
          <p className={styles.bodyDialog}>
            Tem certeza que deseja fazer essa alteração?
          </p>
        }
        footer={
          <>
            <button
              onClick={handleClose}
              type="button"
              className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
            >
              Cancelar
            </button>

            <button
              onClick={handleConfirmation}
              type="button"
              className="text-white bg-green-600 hover:bg-green-800 focus:ring-2 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none"
            >
              Alterar modo
            </button>
          </>
        }
      />
    );
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.modeControl}>
          <div className={styles.titleLock}>
            <h3 className={styles.title}>Controle</h3>
          </div>
          {modesQuery.isLoading || isLoading || config === null ? (
            <BarLoader color="#339933" />
          ) : (
            <div className={styles.toggle}>
              <div>
                <h2 onClick={() => setExpanded(!expanded)}>
                  <button
                    type="button"
                    className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 hover:bg-gray-100"
                  >
                    <span>Controle de rotinas</span>
                    <svg
                      data-accordion-icon
                      className={`w-6 h-6 ${expanded ? "rotate-180" : "rotate-0"
                        } shrink-0`}
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </h2>
                <div className={expanded ? "" : "hidden"}>
                  <div className="p-5 border border-b-0 border-gray-200">
                    <div className={styles.toggleItem}>
                      <h3 className={styles.title}>Fuzzy</h3>
                      <LongSwitch
                        labelOne="Desligada"
                        labelTwo="Ligada"
                        click={() => handleChangeMode("fuzzy")}
                        value={config?.fuzzy}
                      />
                    </div>
                    {Object.keys(config.routines).map((item) => (
                      <div key={item} className={styles.toggleItem}>
                        <h3 className={styles.title}>{titles[item]}</h3>
                        <LongSwitch
                          labelOne="Desligada"
                          labelTwo="Ligada"
                          click={() => handleChangeMode(item)}
                          value={config?.routines[item]}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
          {isDialogVisible && <ConfirmationDialog />}
        </div>

        {config?.routines !== null && config?.routines !== undefined && (
          <Actuators routines={config?.routines} fuzzy={config?.fuzzy!} />
        )}
      </div>
    </>
  );
}

export default Controls;
