import { useContext, useEffect, useState } from "react";

import { APIContext } from "../../contexts/ApiContext";
import { Windrose } from "../Windrose";
import { BarLoader } from "react-spinners";
import styles from "./styles.module.scss";
import { Chart } from "../Chart";
import { ChartProps } from "../../interfaces/Chart";

const minuteInTimestamp = 60000;

type GroupType = {
  id: string;
  name: string;
  type: string;
  subtype: string;
  visible?: boolean;
  data: {
    unit: string;
  };
};

type AverageType = {
  group_id: string;
  value: number;
  create_at: any;
};

function WeatherStationCard({ group }: { group: GroupType }) {
  const { getReadsAveragesApi, getReadsAllAveragesApi } =
    useContext(APIContext);
  const [averages, setAverages] = useState({} as AverageType);
  const [windSpeed, setWindSpeed] = useState({ value: 0 } as AverageType);
  const [isLoading, setIsLoading] = useState(true);
  const [chart, setChart] = useState<ChartProps>({ data: [], labels: [] });

  async function getAverages(groupId: string) {
    setIsLoading(true);

    try {
      const data = await getReadsAveragesApi(groupId);
      if (data === null) {
        setIsLoading(false);
        return;
      }
      const wind = await getReadsAveragesApi("rtWindSpeed");
      setAverages(data);
      setWindSpeed(wind);
    } catch (error) { }
    setIsLoading(false);
  }

  useEffect(() => {
    if (group.id === "rtOutsideTemp" || group.id === "rtOutsideHum") {
      getChartData(group.id);
    }

    getAverages(group.id);

    const interval = setInterval(() => {
      getAverages(group.id);
    }, minuteInTimestamp);

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  const getChartData = async (id: string) => {
    try {
      const chartData: ChartProps = { data: [], labels: [] };
      const startAt = Math.floor((new Date(Date.now() - 8 * 60 * 60 * 1000)).getTime() / 1000)
      const endAt = Math.floor(Date.now() / 1000)
      const reads = await getReadsAllAveragesApi(id, startAt, endAt);

      for (const read of reads.slice(-12)) {
        chartData.data.push(read.value)
        if (!read.timestamp.substring(11, 16).includes("30")) {
          let convertedToInt = parseInt(read.timestamp.substring(11, 13))
          if (convertedToInt === 0) convertedToInt = 24
          if (convertedToInt === 1) convertedToInt = 25
          if (convertedToInt === 2) convertedToInt = 26
          chartData.labels.push((convertedToInt - 3).toString())
        } else {
          chartData.labels.push("")
        }
      }
      setChart(chartData);
    } catch (error) { }
  };

  const icons: { [key: string]: string } = {
    rtOutsideTemp: "thermostat",
    rtOutsideHum: "water_drop",
    rtSolarRad: "sunny",
    rtWindSpeed: "air",
    rtWindDirRose: "explore",
    rtBaroCurr: "timer",
    rtRainRate: "cloudy_snowing",
    rtWindAvgSpeed: "air",
    rtDayRain: "cloudy_snowing",
    rtMonthRain: "cloudy_snowing",
    rtYearRain: "cloudy_snowing",
    rtForecast: "settings_input_antenna",
    rtSunrise: "sunny",
    rtSunset: "sunny",
    rtBaroTrend: "timer",
  };

  return (
    <div className={styles.reads}>
      {isLoading ? (
        <div className={styles.isLoading}>
          <BarLoader color="#339933" />
        </div>
      ) : (
        <>
          <header>
            <h2 className={styles.title}>
              <span className="material-symbols-outlined">
                {icons[group.id]}
              </span>
              {group.name}
            </h2>
          </header>
          <div className={styles.content}>
            {averages != null && Object.keys(averages).length !== 0 ? (
              group.name === "Direção Rosa dos Ventos" ? (
                <Windrose
                  windSpeed={windSpeed.value.toString()}
                  position={averages.value as number}
                />
              ) : (
                <div className={styles.card}>
                  <p className={styles.display}>
                    <span className={styles.value}>{averages.value}</span>
                    <span>{group.data.unit}</span>
                  </p>
                  <p>{new Date(averages.create_at.$date).toLocaleString()}</p>
                </div>
              )
            ) : !isLoading ? (
              <p>Não há dados suficientes.</p>
            ) : null}
          </div>
        </>
      )}
      {chart.data.length > 0 && !isLoading ? (
        <Chart data={chart.data} labels={chart.labels} unit={group.data.unit} />
      ) : null}
    </div>
  );
}

export default WeatherStationCard;
